/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/bulma.css"
import "./src/styles/slick.css"
import "./src/styles/global.css"
import './node_modules/sal.js/dist/sal.css'
import anime from 'animejs/lib/anime.es.js'



export function onRouteUpdate ({ location, prevLocation }) {
    // console.log('new pathname', location.pathname)
    // console.log('old pathname', prevLocation ? prevLocation.pathname : null)

        setTimeout(function() {
            anime({
                targets: '.hidethis',
                 duration: 800,
                 delay: 1200,
                 opacity: [1, 0],
                 easing: 'easeInOutSine',
                 loop: false
            })
        }, 600)

       setTimeout(function(){
        anime.timeline({
         duration: 4800,
         loop: true,
         easing: 'easeInOutSine'
       })
       .add({
         targets: '.heels img:nth-of-type(2)',
         translateY: ['-50px', 0, 0],
         opacity: [0, 1, 0],
       })
       .add({
         targets: '.heels img:nth-of-type(3)',
         translateY: ['-50px', 0, 0],
         opacity: [0, 1, 0],
       }, '-=1600')
       .add({
         targets: '.heels img:nth-of-type(4)',
         translateY: ['-50px', 0, 0],
         opacity: [0, 1, 0],
       }, '-=1600')
       
       }, 2400)

    
}